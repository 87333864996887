<template>
  <div class="quarterly-planner">
    <div class="header">
      <div class="header-content">
        <h1>Q1 2025</h1>
        <div class="progress-info">
          <div>{{ quarterProgress }}% Complete</div>
          <div>{{ remainingDays }} Days Remaining</div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="goal-inputs">
        <v-text-field
          v-model="newGoal"
          placeholder="Enter goal name"
          variant="outlined"
          density="compact"
          class="goal-input"
          bg-color="rgba(255, 255, 255, 0.1)"
          color="white"
        ></v-text-field>
        <v-text-field
          v-model="quantity"
          type="number"
          :min="1"
          variant="outlined"
          density="compact"
          class="quantity-input"
          bg-color="rgba(255, 255, 255, 0.1)"
          color="white"
        ></v-text-field>
        <v-btn @click="addGoal" color="primary">Add Goal</v-btn>
      </div>

      <v-table theme="dark" class="goals-table">
        <thead>
          <tr>
            <th class="text-left">Goal</th>
            <th class="text-left">Quantity</th>
            <th class="text-left">Progress</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="goal in goals" :key="goal.id">
            <td>{{ goal.name }}</td>
            <td>{{ goal.quantity }}</td>
            <td>
              <div class="checkbox-container">
                <div
                  v-for="(checked, index) in goal.completed"
                  :key="index"
                  @click="toggleCheckbox(goal.id, index)"
                  class="checkbox"
                  :class="checked ? 'bg-blue-500 border-blue-500' : 'border-white/20 hover:border-white/50'"
                >
                  <span style="display: flex; justify-content: center; line-height: 18px;" v-if="checked">✓</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'QuarterlyPlanner',
  data() {
    return {
      goals: [],
      newGoal: '',
      quantity: 1
    }
  },
  computed: {
    quarterProgress() {
      const now = new Date();
      const quarterStart = new Date(2025, 0, 1);
      const quarterEnd = new Date(2025, 2, 31);
      const totalDays = 90;
      
      const elapsed = Math.max(0, now - quarterStart) / (1000 * 60 * 60 * 24);
      const progress = Math.min(100, (elapsed / totalDays) * 100);
      
      return progress.toFixed(1);
    },
    remainingDays() {
      const now = new Date();
      const quarterEnd = new Date(2025, 2, 31);
      const remaining = Math.max(0, quarterEnd - now) / (1000 * 60 * 60 * 24);
      return Math.ceil(remaining);
    }
  },
  methods: {
    addGoal() {
      if (this.newGoal.trim() && this.quantity >= 1) {
        this.goals.push({
          id: Date.now(),
          name: this.newGoal,
          quantity: parseInt(this.quantity),
          completed: new Array(parseInt(this.quantity)).fill(false)
        });
        this.newGoal = '';
        this.quantity = 1;
      }
    },
    toggleCheckbox(goalId, index) {
      const goal = this.goals.find(g => g.id === goalId);
      if (goal) {
        goal.completed[index] = !goal.completed[index];
      }
    }
  }
}
</script>

<style scoped>
.quarterly-planner {
  min-height: 100vh;
  background-color: #434f62;
  color: white;
}

.header {
  background-color: #2b3846;
  padding: 2rem;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
}

.header h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.progress-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.goal-inputs {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.goal-input {
  flex-grow: 1;
}

.quantity-input {
  width: 100px;
}

.goals-table {
  background-color: transparent !important;
}

.v-text-field :deep(input) {
  color: white !important;
}

.v-text-field :deep(label) {
  color: rgba(255, 255, 255, 0.7) !important;
}

.checkbox-container {
 display: flex;
 gap: 4px;
 flex-wrap: nowrap;
 max-width: 300px; /* Limit width to prevent wrapping */
 overflow-x: auto; /* Allow horizontal scroll if needed */
}

.checkbox {
 min-width: 18px;
 width: 18px;
 height: 18px;
 border: 1px solid rgba(255, 255, 255, 0.2);
 border-radius: 2px;
 cursor: pointer;
 flex-shrink: 0;
}

.checkbox.checked {
 background-color: #2196F3;
 border-color: #2196F3;
}
</style>