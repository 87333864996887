<!-- WeeklySummary.vue -->
<template>
  <v-dialog :model-value="show" @update:model-value="$emit('update:show', $event)" max-width="90vw">
    <v-card>
      <v-card-title>
        <span class="text-h5">Habit Summary</span>
        <v-spacer></v-spacer>
        <v-btn-toggle v-model="selectedTimeframe" mandatory>
          <v-btn value="1w">1W</v-btn>
          <v-btn value="1m">1M</v-btn>
          <v-btn value="3m">3M</v-btn>
          <v-btn value="all">ALL</v-btn>
        </v-btn-toggle>
      </v-card-title>
      <v-card-text>
        <div class="chart-container">
          <canvas ref="chartCanvas"></canvas>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { auth, db } from '../firebaseInit';
import { getDoc, doc } from 'firebase/firestore';
import { format, startOfDay } from 'date-fns';

// Register Chart.js components
Chart.register(...registerables);

export default {
  name: 'WeeklySummary',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    habits: {
      type: Array,
      required: true
    }
  },
  emits: ['update:show'],
  data() {
    return {
      selectedTimeframe: '1m',
      chart: null,
      chartData: null
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.$nextTick(async () => {
          await this.initChart();
        });
      } else {
        this.destroyChart();
      }
    },
    async selectedTimeframe(newVal, oldVal) {
      if (newVal !== oldVal) {
        await this.updateChart();
      }
    }
  },
  beforeUnmount() {
    this.destroyChart();
  },
  methods: {
    destroyChart() {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
    },

    getDayName(dayIndex) {
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      return days[dayIndex];
    },

    // Convert date to local timezone midnight
    toLocalMidnight(date) {
      return startOfDay(new Date(date));
    },

    // Format date to ISO string in local timezone
    formatDateForFirestore(date) {
      return format(date, 'yyyy-MM-dd');
    },

    // Get habits active for a specific day
    getActiveHabitsForDay(dayName, habits) {
      return habits.filter(habit => 
        habit.days && habit.days[dayName.toLowerCase()]
      );
    },

    async fetchHabitData() {
      try {
        const userId = auth.currentUser.uid;
        const days = this.getTimeframeDays();
        const endDate = this.toLocalMidnight(new Date());
        const startDate = this.toLocalMidnight(new Date());
        startDate.setDate(startDate.getDate() - days);

        const dateArray = this.getDatesInRange(startDate, endDate);
        
        const habitStatusPromises = dateArray.map(async date => {
          const dateStr = this.formatDateForFirestore(date);
          const habitStatusRef = doc(db, `users/${userId}/habitStatus/${dateStr}`);
          return getDoc(habitStatusRef);
        });

        const snapshots = await Promise.all(habitStatusPromises);
        
        const habitData = {};
        this.habits.forEach(habit => {
          habitData[habit.name] = [];
        });

        dateArray.forEach((date, index) => {
          const snapshot = snapshots[index];
          const data = snapshot.exists() ? snapshot.data() : {};
          
          const dayOfWeek = date.getDay();
          const dayName = this.getDayName(dayOfWeek).toLowerCase();
          
          // Get habits active for this day
          const activeHabits = this.getActiveHabitsForDay(dayName, this.habits);

          this.habits.forEach(habit => {
            if (habit.days && habit.days[dayName]) {
              // Calculate contribution only if the habit is active on this day
              const contribution = activeHabits.length > 0 ? 1 / activeHabits.length : 0;
              habitData[habit.name].push({
                x: date,
                y: data[habit.name] ? contribution : 0
              });
            } else {
              // Push null for days when the habit is not scheduled
              habitData[habit.name].push({
                x: date,
                y: null
              });
            }
          });
        });

        return habitData;
      } catch (error) {
        console.error('Error fetching habit data:', error);
        return {};
      }
    },

    getDatesInRange(startDate, endDate) {
      const dates = [];
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        dates.push(this.toLocalMidnight(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dates;
    },

    getTimeframeDays() {
      switch (this.selectedTimeframe) {
        case '1w': return 7;
        case '1m': return 30;
        case '3m': return 90;
        case 'all': return 365;
        default: return 30;
      }
    },

    createChartConfig(habitData) {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      const chartArea = ctx.canvas.getBoundingClientRect();
      
      const gradient = ctx.createLinearGradient(0, 0, 0, chartArea.height);
      gradient.addColorStop(0, 'rgba(120, 237, 255, 0.2)');
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

      return {
        type: 'line',
        data: {
          datasets: Object.entries(habitData).map(([habitName, data]) => ({
            label: habitName,
            data: data,
            fill: true,
            backgroundColor: gradient,
            borderColor: '#78edff',
            tension: 0.4,
            borderWidth: 2,
            pointStyle: 'circle',
            radius: 0,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#78edff',
            pointHoverBorderWidth: 2,
            pointHoverBorderColor: '#ffffff',
            stack: 'stack1',
            spanGaps: true // This will skip null values in the line
          }))
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index',
            intersect: false
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: function(context) {
                  // Only show habits that have a non-null value
                  if (context.raw && context.raw.y !== null) {
                    return `${context.dataset.label}: ${(context.parsed.y * 100).toFixed(0)}%`;
                  }
                  return null;
                },
                footer: function(tooltipItems) {
                  // Calculate total only for non-null values
                  const sum = tooltipItems.reduce((total, item) => {
                    if (item.raw && item.raw.y !== null) {
                      return total + item.parsed.y;
                    }
                    return total;
                  }, 0);
                  return `Total: ${(sum * 100).toFixed(0)}%`;
                }
              },
              filter: function(tooltipItem) {
                // Filter out null values from tooltip
                return tooltipItem.raw && tooltipItem.raw.y !== null;
              },
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              titleColor: '#666',
              bodyColor: '#666',
              borderColor: '#e1e1e1',
              borderWidth: 1,
              padding: 10,
              displayColors: true
            }
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'MMM d'
                },
                tooltipFormat: 'PPP'
              },
              adapters: {
                date: {
                  zone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }
              },
              grid: {
                display: false
              },
              border: {
                display: false
              },
              ticks: {
                display: true,
                color: '#999',
                font: {
                  size: 11
                },
                maxRotation: 45,
                minRotation: 45
              }
            },
            y: {
              stacked: true,
              beginAtZero: true,
              max: 1,
              grid: {
                display: false
              },
              border: {
                display: false
              },
              ticks: {
                display: true,
                color: '#999',
                font: {
                  size: 11
                },
                callback: function(value) {
                  return (value * 100) + '%';
                },
                padding: 10,
                stepSize: 0.2
              }
            }
          },
          layout: {
            padding: {
              top: 20,
              right: 20,
              bottom: 20,
              left: 20
            }
          }
        }
      };
    },

    async initChart() {
      try {
        this.destroyChart();
        const habitData = await this.fetchHabitData();
        const ctx = this.$refs.chartCanvas.getContext('2d');
        const config = this.createChartConfig(habitData);
        this.chart = new Chart(ctx, config);
      } catch (error) {
        console.error('Error initializing chart:', error);
      }
    },

    async updateChart() {
      try {
        if (this.chart) {
          const habitData = await this.fetchHabitData();
          const config = this.createChartConfig(habitData);
          
          this.destroyChart();
          const ctx = this.$refs.chartCanvas.getContext('2d');
          this.chart = new Chart(ctx, config);
        }
      } catch (error) {
        console.error('Error updating chart:', error);
      }
    }
  }
}
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 80vh;
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 20px;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

.v-card-title {
  display: flex;
  align-items: center;
  padding: 16px;
}

.v-btn-toggle {
  margin-left: 16px;
}
</style>