<template>
  <v-app>
    <v-main>
      <div v-if="!isAuthenticated">

        <div class="container">
          <image-carousel v-if="!isAuthenticated"></image-carousel>
          <demo-life v-if="!isAuthenticated"></demo-life>
          <div class="login-toggle-parent">
            <v-btn-toggle v-model="selectedForm" mandatory class="login-toggle">
              <v-btn value="register">Register</v-btn>
              <v-btn value="login">Login</v-btn>
            </v-btn-toggle>
          </div>
          <div v-if="selectedForm === 'register'">
            <!-- Registration Form -->
            <form class="registration-form" @submit.prevent="registerUser">
              <v-text-field v-model="name" label="Name" required></v-text-field>
              <p class="error" v-if="birthdateError">{{ birthdateError }}</p>
              <v-text-field v-model="birthdate" type="date" required></v-text-field>
              <p class="error" v-if="errorMessage">{{ errorMessage }}</p>
              <v-text-field v-model="email" label="Email" type="email" required></v-text-field>
              
              <p class="error" v-if="passwordError">{{ passwordError }}</p>
              <v-text-field v-model="password" label="Password" type="password" required></v-text-field>

              <div style="display: inline-flex; gap: 10px;">
                <v-btn type="submit">Register</v-btn>
              </div>
            </form>
          </div>
          <div v-if="selectedForm === 'login'">
            <!-- Login Form -->
            <form @submit.prevent="loginUser" class="login-form">
              <v-text-field v-model="email" label="Email" type="email" required></v-text-field>
              <v-text-field v-model="password" label="Password" type="password" autocomplete="current-password" required></v-text-field>
              <div v-if="loginError" class="error-message">{{ loginError }}</div>
              <div style="display: inline-flex; gap: 10px;">
                <v-btn type="submit" :disabled="loading">Login</v-btn>
              </div>
            </form>
          </div>
          <!-- <v-btn @click="showPrivacyPolicy = true">Privacy Policy</v-btn> -->
          <v-divider class="my-3"></v-divider>
              Developed by Daniel Ionashku
              <div><a href="https://buymeacoffee.com/ionashku" target="_blank">show support by buying me a coffee</a> ☕️</div>
              <v-divider class="my-3"></v-divider>
              <v-divider class="my-3"></v-divider>
              <v-divider class="my-3"></v-divider>
              <div>Scroll down for Privacy Policy ⤵</div>
        </div>

      </div>
      <div v-else>
        <!-- Authenticated content -->
        <div class="life-view-container">
          <WeeklySummary
            :show="showWeeklySummary"
            :habits="dailyHabits"
            @update:show="showWeeklySummary = $event"
          />
          <WeeklyReflection 
            :show="showWeeklyReflection"
            :week-number="currentWeekNumber"
            :birthdate="birthdate"
            @update:show="showWeeklyReflection = $event"
          />
          <div v-if="showQuarterlyGoals">
            <QuarterlyGoals />
          </div>
          <div 
            v-if="showCompletionBackground" 
            class="completion-background"
            :class="{ 'completion-background-enabled': showCompletionBackground }"
          >
          <div 
            class="completion-fill"
            :style="{ 
              height: `${habitCompletionPercentage}%`,
              opacity: showCompletionBackground ? 1 : 0
            }"
          ></div>
          <div 
            v-if="showCompletionBackground" 
            class="completion-percentage"
          >
            {{ habitCompletionPercentage }}%
          </div>
          </div>

            <!-- Quote at the top -->
            <div v-if="quotePosition === 'top'" class="quote-container quote-top">
              <div v-if="showQuotes" class="random-quote">{{ randomQuote }}</div>
              <div v-if="showHoursUntilDeath" class="hours-until-80">{{ hoursUntilDeath }}<span class="hours-until-label">Hours to go</span></div>
            </div>
            <!-- Quote on the side (existing implementation) -->
            <div v-if="quotePosition === 'side'" class="daily-quote">
              <div v-if="showQuotes" class="random-quote">{{ randomQuote }}</div>
              <div v-if="showHoursUntilDeath" class="hours-until-80"> {{ hoursUntilDeath }}<span class="hours-until-label">Hours to go</span></div>
            </div>
            <div class="life-grid" :class="{ 'compact-view': compactView }">
              <div v-if="hoveredWeek" class="month-year-overlay" ref="overlay">
                {{ formatMonthYear(hoveredWeek.week) }} - Age: {{ formatAge(hoveredWeek.week.age) }}
              </div>
              <div
                v-for="(year, yearIndex) in years"
                :key="yearIndex"
                :class="['year-row', { 'year-gap': showYearGaps && (yearIndex + 1) % 5 === 0 }]"
              >
              <div
                v-for="(week, weekIndex) in year"
                :key="weekIndex"
                :class="[
                  'square',
                  { 'past-week': week.isPast },
                  { 'current-week': week.isCurrent },
                  { 'has-milestone': hasMilestone(week.number) },
                  getLifeStageColor(week.age)
                ]"
                @click="zoomWeek(week)"
                @mouseenter="showOverlay(week, $event)"
                @mouseleave="hoveredWeek = null"
              >
                  <div v-if="hasMilestone(week.number) && !isZoomedViewOpen" class="milestone-indicator"></div>
                </div>
              </div>
              <div v-if="zoomedWeek" class="zoomed-view-backdrop" @click.self="closeZoomedView">
                <div class="zoomed-view" ref="zoomedViewRef">
                  <button class="mobile-close-button" @click="closeZoomedView">×</button>
                  <div class="arrow-button left-arrow" @click="goToPreviousWeek">&lt; {{ zoomedWeek.number - 1 }}</div>
                  <div class="arrow-button right-arrow" @click="goToNextWeek">{{ zoomedWeek.number + 1 }} &gt;</div>
                  <div class="progress-container">
                    <div class="progress-bar" :style="{ width: progressPercentage + '%' }"></div>
                  </div>
                  <div class="week-header">
                    
                    <div class="week-number">#{{ zoomedWeek.number }}<div class="week-title">WEEK</div></div>
                    <div class="age">{{ formatAge(zoomedWeek.age) }}<div class="age-title">AGE</div></div>
                  </div>
                  <div class="week-details">
                      <p>{{ formatDate(zoomedWeek.startDate) }} - {{ formatDate(zoomedWeek.endDate) }}</p>
                    </div>
                    <div class="summary-section">
                      <div v-if="!editingSummary" class="summary-text" :class="{ 'no-summary': !weekSummary }" @click="startEditingSummary">
                        {{ weekSummary || 'Click to add a summary' }}</div>
                      <v-textarea v-else v-model="weekSummary" density="compact" label="Summary" variant="outlined" @blur="saveSummary"></v-textarea>
                    </div>
                    <div class="reflection-section">
                      <div class="reflection-header" @click="toggleReflectionExpanded">
                        <span>Weekly Reflection</span>
                        <v-icon>{{ reflectionExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      </div>
                      <div v-if="reflectionExpanded" class="reflection-content">
                        <div v-if="weekReflection">
                          <!-- Display ratings -->
                          <div v-if="weekReflection.ratings && Object.keys(weekReflection.ratings).length > 0" class="ratings-section">
                            <h4>Weekly Ratings</h4>
                            <div class="ratings-grid">
                              <div class="rating-item" v-for="(value, key) in weekReflection.ratings" :key="key">
                                <div class="rating-label">{{ formatRatingLabel(key) }}</div>
                                <div class="rating-value">{{ value }}/10</div>
                                <div class="rating-bar">
                                  <div class="rating-fill" :style="{ width: (value * 10) + '%', backgroundColor: getRatingColor(value) }"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <!-- Display reflections -->
                          <div v-if="weekReflection.reflections && Object.keys(weekReflection.reflections).length > 0" class="reflections-section">
                            <h4>Written Reflections</h4>
                            <div class="reflection-item" v-for="(value, key) in filteredReflections" :key="key">
                              <div class="reflection-question">{{ formatReflectionQuestion(key) }}</div>
                              <div class="reflection-answer">{{ value }}</div>
                            </div>
                          </div>
                        
                        </div>
                        <div v-else class="no-reflection">
                          No reflection data available for this week.
                        </div>
                      </div>
                    </div>
                  <v-btn v-if="editingSummary" class="weekly-summary-button" @click="saveSummary" :disabled="isSaving">
                      {{ isSaving ? 'Saving...' : 'Save Summary' }}
                    </v-btn>
                  <div v-if="weekGoals.length > 0" class="goal-list">
                    <h3>Milestones:</h3>
                    <div v-for="(goal, index) in weekGoals" :key="index">
                      <v-icon class="delete-icon" @click="deleteMilestone(index)">mdi-close</v-icon>
                      {{ goal }}
                    </div>
                  </div>

                  <div class="goal-section">
                    <v-text-field density="compact" v-model="goalInput" label="Milestones" placeholder="Enter a milestone for this week" variant="outlined" @keyup.enter="saveGoal"></v-text-field>
                    <v-btn  class="weekly-summary-button"  @click="saveGoal">ADD</v-btn>
                  </div>
                  <div v-if="weekTodos.length > 0" class="to-do-list">
                    <h3>To Do:</h3>
                    <div class="todo-progress-container">
                      <div class="todo-progress-bar" :style="{ width: todoProgress + '%' }"></div>
                    </div>
                    <div v-for="(todo, index) in weekTodos" :key="index" class="todo-item">
                      <div class="todo-content">
                        <v-icon class="delete-icon" @click="deleteTodo(todo)">mdi-close</v-icon>
                        <v-checkbox v-model="todo.done" @change="updateTodoStatus(todo)" class="habit-checkbox" hide-details></v-checkbox>
                        <span :class="{ 'todo-done': todo.done }" class="todo-text">{{ todo.text }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="todo-section">
                    <v-text-field density="compact" v-model="todoInput" label="Add To Do" placeholder="Enter a to-do item" variant="outlined" class="todo-input" @keyup.enter="addTodo"></v-text-field>
                    <v-btn class="add-todo-button weekly-summary-button" @click="addTodo">ADD</v-btn>
                  </div>
                  <div class="weeks-until-80">{{ weeksUntil80 }} weeks until you turn 80</div>
                </div>
              </div>
              <span class="settings-icon" @click="toggleSettingsDrawer">⚙️</span>
            <v-dialog v-model="showSettingsDrawer" max-width="750" :position="{ top: '60px', right: '20px' }" @click:outside="showSettingsDrawer = false">
              <v-card>
                <v-card-title><h3>Settings</h3></v-card-title>
                <v-divider class="my-3"></v-divider>
                <v-card-text>
                  <v-switch 
                    class="settings-toggle" 
                    v-model="showCompletionBackground" 
                    label="Show Completion Background" 
                    :color="showCompletionBackground ? 'blue' : 'default'"
                  ></v-switch>
                  <v-switch 
                    class="settings-toggle" 
                    v-model="showQuarterlyGoals" 
                    label="Quarterly Goals View" 
                    :color="showQuarterlyGoals ? 'blue' : 'default'"
                  ></v-switch>
                  <v-switch class="settings-toggle" v-model="showYearGaps" label="Show 5 Year Gaps" :color="showYearGaps ? 'blue' : 'default'"></v-switch>
                  <v-switch class="settings-toggle" v-model="compactView" label="Compact View" :color="compactView ? 'blue' : 'default'"></v-switch>
                  <v-switch class="settings-toggle" v-model="showHoursUntilDeath" label="Show Hours To Go" :color="showHoursUntilDeath ? 'blue' : 'default'"></v-switch>
                  <div v-if="showHoursUntilDeath" class="decimal-places-setting">
                    <v-slider
                      v-model="hoursDecimalPlaces"
                      :min="0"
                      :max="10"
                      :step="1"
                      thumb-label
                      :label="`Decimal Places: ${hoursDecimalPlaces}`"
                      @change="saveHoursDecimalPlaces"
                      class="decimal-places-slider"
                    ></v-slider>
                  </div>
                  <v-switch class="settings-toggle" v-model="showWeeklyTodos" label="Show Weekly Todos" :color="showWeeklyTodos ? 'blue' : 'default'"></v-switch>
                  <v-switch class="settings-toggle" v-model="showQuotes" label="Show Quotes" :color="showQuotes ? 'blue' : 'default'"></v-switch>
                  <h3>Quote Position</h3>
                    <v-btn-toggle v-model="quotePosition" mandatory>
                      <v-btn value="top">Top</v-btn>
                      <v-btn value="side">Side</v-btn>
                      <v-btn value="bottom">Bottom</v-btn>
                    </v-btn-toggle>
                  <v-divider class="my-3"></v-divider>
                  <div style="margin-bottom: 20px;">
                    <h3 style="margin-bottom: 20px;">Manage Daily Habits</h3>
                    <div style="display: flex; flex-direction: column; gap: 10px;">
                      <v-text-field
                        density="compact"
                        v-model="newHabitName"
                        label="New Habit"
                        @keyup.enter="addHabit"
                        placeholder="Enter a habit"
                        variant="outlined"
                        class="todo-input"
                      ></v-text-field>
                      
                      <div class="days-selector">
                        <div class="days-checkboxes">
                          <v-checkbox
                            v-for="day in ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']"
                            :key="day"
                            v-model="newHabitDays[day]"
                            :label="getDayLabel(day)"
                            density="compact"
                            hide-details
                            class="day-checkbox"
                          ></v-checkbox>
                        </div>
                      </div>

                      <div style="display: flex; margin: 0 auto">
                        <v-btn class="add-habit-button" @click="addHabit">ADD HABIT</v-btn>
                      </div>
                    </div>

                    <v-list class="habit-list">
                      <v-list-item v-for="habit in dailyHabits" :key="habit.id" class="habit-list-item">
                        <div class="habit-list-content">
                          <v-icon class="delete-icon" @click="deleteHabit(habit)" style="font-size: 14px;">mdi-close</v-icon>
                          <span class="habit-name">{{ habit.name }}</span>
                          <select v-model="habit.sphere" placeholder="Group" @change="updateHabitGroup(habit)" class="habit-group-select">
                            <option disabled value="">Select Group</option>
                            <option v-for="group in spheres" :key="group" :value="group">
                              {{ group }}
                            </option>
                          </select>
                          <div class="habit-days">
                            <span 
                              v-for="(dayLabel, index) in ['M', 'T', 'W', 'T', 'F', 'S', 'S']" 
                              :key="index"
                              :class="[
                                'day-indicator',
                                { 'day-active': habit.days && habit.days[getDayName(index)] }
                              ]"
                              @click="toggleHabitDay(habit, getDayName(index))"
                            >
                              {{ dayLabel }}
                            </span>
                          </div>
                        </div>
                      </v-list-item>
                    </v-list>
                  </div>

                  

                    <div style="width: 100%; display: flex; margin: 0 auto; margin-bottom: 20px;">
                      <v-btn style="margin: 0 auto;" class="signoutbutton" @click="signOut">Sign Out</v-btn>
                    </div>
                    <v-divider class="my-3"></v-divider>
                    <v-card-text class="support-text">
                      Developed by Daniel Ionashku — 
                      <a href="https://buymeacoffee.com/ionashku" target="_blank">show support by buying me a coffee.</a> ☕️
                    </v-card-text>
                </v-card-text>
              </v-card>
            </v-dialog>
            </div>
            <div v-if="showWeeklyTodos && currentWeekTodos.length > 0" 
              class="weekly-todos"
              :class="{ 'hidden-when-zoomed': isZoomedViewOpen }">
                <h4>This Week's Todos</h4>
                <div class="current-week-todo-progress-container">
                  <div class="todo-progress-bar" :style="{ width: currentWeekTodoProgress + '%' }"></div>
                </div>
                <div v-for="todo in currentWeekTodos" :key="todo.id" class="todo-item">
                  <v-checkbox v-model="todo.done" @change="updateTodoStatus(todo)" hide-details dense></v-checkbox>
                  <span :class="{ 'todo-done': todo.done }" class="todo-text">{{ todo.text }}</span>
                </div>
                <div class="todo-section2">
                  <v-text-field density="compact" v-model="todoInput" label="Add To Do" placeholder="Enter a to-do item" variant="outlined" class="todo-input" @keyup.enter="addTodo"></v-text-field>
                  <v-btn style="height: 43px;" class="add-todo-button weekly-summary-button" @click="addTodo">ADD</v-btn>
                </div>
            </div>
            <div v-if="filteredDailyHabits.length > 0 && !showQuarterlyGoals" class="daily-habits">
              <div class="habits-header">
                <h4 @click="toggleDatePicker">
                  <span :style="datePickerStyle">
                    {{ new Date(selectedDate).toLocaleDateString() }}
                    <v-icon small>mdi-calendar</v-icon>
                  </span>
                </h4>
                
                <v-expand-transition>
                  <div v-if="showDatePicker" class="date-picker-container">
                    <v-date-picker
                      v-model="selectedDate"
                      @input="changeSelectedDate"
                      :max="getCurrentLocalDate()"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </div>
                </v-expand-transition>
              </div>
              <div v-for="habit in filteredDailyHabits" :key="habit.name" class="habit-item">
                <v-checkbox v-model="habit.completed" @change="updateHabitStatus(habit)" hide-details dense></v-checkbox>
                <span :class="{ 'habit-done': habit.completed }" class="habit-text">{{ habit.name }}</span>
              </div>
            </div>
            <!-- Quote at the bottom -->
            <div v-if="quotePosition === 'bottom'" class="quote-container quote-bottom">
              <div v-if="showQuotes" class="random-quote">{{ randomQuote }}</div>
              <div v-if="showHoursUntilDeath" class="hours-until-80"> <div style="display: flex; margin: 0 auto"> {{ hoursUntilDeath }}</div><span class="hours-until-label">Hours to go</span></div>
            </div>

        </div>
      </div>
    </v-main>
  </v-app>
</template>



<script>
/* eslint-disable */
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, getDoc, addDoc, where, writeBatch, deleteDoc, query, orderBy, updateDoc, doc, setDoc, deleteField } from 'firebase/firestore';
import { auth, db } from './firebaseInit';
import WeeklySummary from './components/WeeklySummary.vue';
import DemoLife from './components/DemoLife.vue';
import ImageCarousel from './components/ImageCarousel.vue';
import WeeklyReflection from './components/WeeklyReflection.vue';
import QuarterlyGoals from './components/QuarterlyGoals.vue';



export default {

  name: 'App',
  components: {
    WeeklySummary,
    DemoLife,
    ImageCarousel,
    WeeklyReflection,
    QuarterlyGoals
  },
  data() {
    return {
      showWeeklySummary: false,
      showQuarterlyGoals: false,
      isAuthenticated: false,
      showPrivacyPolicy: false,
      showWeeklyReflection: false,
      showCompletionBackground: true,
      weeklyCheckInterval: null,
      reflectionExpanded: false,
      weekReflection: null,
      lastCheckedWeekNumber: null,
      selectedForm: 'login',
      selectedDate: new Date(),
      showDatePicker: false,
      isCurrentDay: true,
      isLoading: false,
      birthdate: null,
      hoursTimer: null,
      hoursDecimalPlaces: 6,
      currentHours: '0',
      lastUpdate: null,
      years: [],
      spheres: ['Friends', 'Family', 'Faith', 'Fitness', 'Finance', 'Future', 'Fun'],
      zoomedWeek: null,
      goalInput: '',
      weeksUntil80: 0,
      weekGoals: [],
      showYearGaps: false,
      isSaving: false,
      showSettingsDrawer: false,
      weekSummary: '',
      quotePosition: 'side',
      isAuthenticated: false,
      selectedForm: 'login',
      name: '',
      email: '',
      password: '',
      birthdate: '',
      errorMessage: '',
      passwordError: '',
      birthdateError: '',
      termsAccepted: false,
      loginError: '',
      dailyHabits: [],
      loading: false,
      newHabitName: '',
      showWeeklyTodos: true,
      currentWeekTodos: [],
      newHabitDays: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
      },
      quotes: [
        "Waste no more time arguing what a good man should be. Be One. – Marcus Aurelius",
        "Think of the life you have lived until now as over and, as a dead man, see what's left as a bonus and live it according to Nature. Love the hand that fate deals you and play it as your own, for what could be more fitting? – Marcus Aurelius",
        "It never ceases to amaze me: we all love ourselves more than other people, but care more about their opinion than our own. – Marcus Aurelius",
        "In your actions, don't procrastinate. In your conversations, don't confuse. In your thoughts, don't wander. In your soul, don't be passive or aggressive. In your life, don't be all about business. – Marcus Aurelius",
        "If it is not right, do not do it, if it is not true, do not say it. – Marcus Aurelius",
        "The best revenge is not to be like your enemy. – Marcus Aurelius",
        "Choose not to be harmed — and you won't feel harmed. Don't feel harmed — and you haven't been. – Marcus Aurelius",
        "It's time you realized that you have something in you more powerful and miraculous than the things that affect you and make you dance like a puppet. – Marcus Aurelius",
        "External things are not the problem. It's your assessment of them. Which you can erase right now. – Marcus Aurelius",
        "If anyone can refute me—show me I'm making a mistake or looking at things from the wrong perspective—I'll gladly change. It's the truth I'm after, and the truth never harmed anyone. – Marcus Aurelius",
        "You could leave life right now. Let that determine what you do and say and think. – Marcus Aurelius",
        "Be tolerant with others and strict with yourself. – Marcus Aurelius",
        "We are more often frightened than hurt; and we suffer more in imagination than in reality. – Seneca",
        "If a man knows not which port he sails, no wind is favorable. – Seneca",
        "No person has the power to have everything they want, but it is in their power not to want what they don't have, and to cheerfully put to good use what they do have. – Seneca",
        "Nothing, to my way of thinking, is a better proof of a well ordered mind than a man's ability to stop just where he is and pass some time in his own company. – Seneca",
        "He who fears death will never do anything worthy of a man who is alive. – Seneca",
        "This is our big mistake: to think we look forward to death. Most of death is already gone. Whatever time has passed is owned by death. – Seneca",
        "Life is very short and anxious for those who forget the past, neglect the present, and fear the future. – Seneca",
        "I judge you unfortunate because you have never lived through misfortune. You have passed through life without an opponent—no one can ever know what you are capable of, not even you. – Seneca",
        "How does it help…to make troubles heavier by bemoaning them? – Seneca",
        "People are frugal in guarding their personal property; but as soon as it comes to squandering time they are most wasteful of the one thing in which it is right to be stingy. – Seneca",
        "How long are you going to wait before you demand the best for yourself? – Epictetus",
        "Don't seek for everything to happen as you wish it would, but rather wish that everything happens as it actually will—then your life will flow well. – Epictetus",
        "First say to yourself what you would be; and then do what you have to do. – Epictetus",
        "Curb your desire—don't set your heart on so many things and you will get what you need. – Epictetus",
        "That's why the philosophers warn us not to be satisfied with mere learning, but to add practice and then training. For as time passes we forget what we learned and end up doing the opposite, and hold opinions the opposite of what we should. – Epictetus",
        "Don't explain your philosophy. Embody it. – Epictetus",
        "The chief task in life is simply this: to identify and separate matters so that I can say clearly to myself which are externals not under my control, and which have to do with the choices I actually control. Where then do I look for good and evil? Not to uncontrollable externals, but within myself to the choices that are my own… – Epictetus",
        "If anyone tells you that a certain person speaks ill of you, do not make excuses about what is said of you but answer, 'He was ignorant of my other faults, else he would have not mentioned these alone.' – Epictetus",
        "I begin to speak only when I'm certain what I'll say isn't better left unsaid. – Cato",
        "What man actually needs is not a tensionless state but rather the striving and struggling for some goal worthy of him. – Viktor Frankl",
        "When we are no longer able to change a situation, we are challenged to change ourselves. – Viktor Frankl",
        "Life is long if you know how to use it. – Seneca",
        "True happiness is to enjoy the present, without anxious dependence upon the future. – Seneca",
        "It is the power of the mind to be unconquerable. – Seneca",
        "It is not because things are difficult that we do not dare; it is because we do not dare that they are difficult. – Seneca",
        "Difficulties strengthen the mind, as labor does the body. – Seneca",
        "We suffer more in imagination than in reality. – Seneca",
        "Ignorance is the cause of fear. – Seneca",
        "The whole future lies in uncertainty: live immediately. – Seneca",
        "Sometimes even to live is an act of courage. – Seneca",
        "If you really want to escape the things that harass you, what you're needing is not to be in a different place but to be a different person. – Seneca",
        "It's not what happens to you, but how you react to it that matters. – Epictetus",
        "Men are disturbed not by things, but by the views which they take of them. – Epictetus",
        "Freedom is the only worthy goal in life. It is won by disregarding things that lie beyond our control. – Epictetus",
        "Wealth consists not in having great possessions, but in having few wants. – Epictetus",
        "It is difficulties that show what men are. – Epictetus",
        "Happiness and freedom begin with a clear understanding of one principle: Some things are within our control, and some things are not. – Epictetus",
        "The key is to keep company only with people who uplift you, whose presence calls forth your best. – Epictetus",
        "The greater the difficulty, the more glory in surmounting it. Skillful pilots gain their reputation from storms and tempests. – Epictetus",
        "If you want to improve, be content to be thought foolish and stupid. – Epictetus",
        "He is a wise man who does not grieve for the things which he has not, but rejoices for those which he has. – Epictetus",
        "Demand not that things happen as you wish, but wish them to happen as they do, and you will go on well. – Epictetus",
        "The goal of life is living in agreement with nature. – Zeno of Citium",
        "Better to trip with the feet than with the tongue. – Zeno of Citium",
        "Man conquers the world by conquering himself. – Zeno of Citium",
        "A bad feeling is a commotion of the mind repugnant to reason, and against nature. – Zeno of Citium",
        "Happiness is a good flow of life. – Zeno of Citium",
        "Nothing is more hostile to a firm grasp on knowledge than self-deception. – Zeno of Citium",
        "Follow where reason leads. – Zeno of Citium",
        "Well-being is realized by small steps, but it is truly no small thing. – Zeno of Citium",
        "Fate is the endless chain of causation, whereby things are; the reason or formula by which the world goes on. – Zeno of Citium",
        "No loss should be more regrettable to us than losing our time, for it's irretrievable. – Zeno of Citium",
        "If you accomplish something good with hard work, the labor passes quickly, but the good endures; if you do something shameful in pursuit of pleasure, the pleasure passes quickly, but the shame endures. – Gaius Musonius Rufus",
        "The greatest thing in the world is self-sufficiency. – Gaius Musonius Rufus",
        "It is impossible for a man to learn what he thinks he already knows. – Gaius Musonius Rufus",
        "From good people you'll learn good, but if you mingle with the bad, you'll destroy such soul as you had. – Gaius Musonius Rufus",
        "It is not possible to live well today unless you treat it as your last day. – Gaius Musonius Rufus",
        "In order to protect ourselves we must live like doctors and be continually treating ourselves with reason. – Gaius Musonius Rufus",
        "You will earn the respect of all if you begin by earning the respect of yourself. Don't expect to encourage good deeds in people conscious of your own misdeeds. – Gaius Musonius Rufus",
        "Willingly accept the inevitable, and you will lead a life in harmony with the universe. – Gaius Musonius Rufus",
        "Train yourself to strive for giving, rather than receiving. – Gaius Musonius Rufus",
        "The soul is trained for courage when we show patience under hardships. – Gaius Musonius Rufus",
        "It is the privilege of the gods to want nothing, and of godlike men to want little. – Diogenes",
        "It takes a wise man to discover a wise man. – Diogenes",
        "People who talk well but do nothing are like musical instruments; the sound is all they have to offer. – Diogenes",
        "No man is hurt but by himself. – Diogenes",
        "He has the most who is most content with the least. – Diogenes",
        "As a matter of self-preservation, a man needs good friends or ardent enemies, for the former instruct him and the latter take him to task. – Diogenes",
        "Courage is the key to mastery; the key to conquering our fears and unlocking our true potential. – Diogenes",
        "The greatest wealth is to live content with little, for there is never a lack for those who crave much. – Diogenes",
        "The wise man knows that he knows nothing, and yet he still seeks knowledge. – Diogenes",
        "If I gained one thing from philosophy is that at the very least, I am well prepared to confront any change in fortune. – Diogenes",
        "There is only a finger's difference between a wise man and a fool. – Diogenes"
      ],
      randomQuote: '',
      showQuotes: true,
      todoInput: '',
      hoveredWeek: null,
      weekTodos: [],
      editingSummary: false,
      showHoursUntilDeath: false,
      milestones: [],
      compactView: true,
      lifeStages: [
      { name: 'Early Years', startAge: 0, endAge: 5.999, color: '#ffebee' },
      { name: 'School Years', startAge: 6, endAge: 17.999, color: '#e8f5e9' },
      { name: 'Young Adult', startAge: 18, endAge: 29.999, color: '#e3f2fd' },
      { name: 'Adulthood', startAge: 30, endAge: 59.999, color: '#f3e5f5' },
      { name: 'Senior Years', startAge: 60, endAge: 80, color: '#fbe9e7' },
    ],
    };
  },

  created() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User already authenticated");
        this.isAuthenticated = true;
        this.fetchUserData().then(() => {
          // Set up weekly check after user data is loaded
          this.setupWeeklyCheck();
        });
        // Hide privacy policy when authenticated
        const privacyPolicy = document.querySelector('.privacy-policy');
        if (privacyPolicy) {
          privacyPolicy.style.display = 'none';
        }
      } else {
        this.isAuthenticated = false;
        // Show privacy policy when not authenticated
        const privacyPolicy = document.querySelector('.privacy-policy');
        if (privacyPolicy) {
          privacyPolicy.style.display = 'block';
        }
      }
    });
    this.startHoursTimer();
    this.selectRandomQuote();
  },
  beforeDestroy() {
    this.stopHoursTimer();
  },

  mounted() {
    this.selectedDate = new Date();
    const exactToday = this.getLocalDateString(this.selectedDate);
    console.log('MOUNTED: Setting to EXACT TODAY:', exactToday);
    this.fetchHabitStatusForDate(exactToday);
    window.addEventListener('keydown', this.handleKeyPress);
  },

  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
    clearInterval(this.quoteChangeInterval);
  },

  watch: {
    showYearGaps(newValue) {
      this.saveShowYearGapsState();
    },
    showQuarterlyGoals(newValue) {
      this.saveUserSetting('showQuarterlyGoals', newValue);
    },
    quotePosition(newValue) {
      this.saveUserSetting('quotePosition', newValue);
    },
    async showCompletionBackground(newValue) {
      this.saveUserSetting('showCompletionBackground', newValue);
    },
    selectedDate: {
      immediate: true,
      handler(newDate) {
        console.log(`Date changed to: ${newDate}. Is current day: ${this.isCurrentDay}`);
        const formattedDate = this.getLocalDateString(new Date(newDate));
        if (formattedDate !== this.getCurrentLocalDate()) {
          console.log(`Fetching habits for a non-current day: ${formattedDate}`);
        } else {
          console.log(`Fetching habits for the current day: ${formattedDate}`);
        }
        this.fetchHabitStatusForDate(formattedDate);
      },
    },
    filteredDailyHabits: {
      deep: true,
      handler() {
        // Force update of the completion background
        this.$nextTick(() => {
          const element = this.$el.querySelector('.completion-fill');
          if (element) {
            element.style.height = `${this.habitCompletionPercentage}%`;
          }
        });
      }
    },
    async showHoursUntilDeath(newValue) {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, `users/${userId}`);
      await updateDoc(userRef, {
        showHoursUntilDeath: newValue,
      });
    },
    showWeeklyTodos(newValue) {
      this.saveUserSetting('showWeeklyTodos', newValue);
    },
    showQuotes(newValue) {
      this.saveUserSetting('showQuotes', newValue);
    },
    
    compactView(newValue) {
      this.saveCompactViewState();
    },
    dailyHabits: {
    deep: true,
    handler() {
      this.updateCompletionFill();
    }
  },

  // Add a watcher for filtered habits
    filteredDailyHabits: {
      immediate: true,
      handler() {
        this.updateCompletionFill();
      }
    },

    // Watch the completion percentage directly
    habitCompletionPercentage(newValue) {
      this.updateCompletionFill();
    },
    hoursDecimalPlaces: {
      handler(newValue) {
        if (auth.currentUser) {
          this.saveHoursDecimalPlaces(newValue);
        }
      }
    }
  },
  computed: {
    isZoomedViewOpen() {
      return !!this.zoomedWeek;
    },
    filteredReflections() {
      if (!this.weekReflection || !this.weekReflection.reflections) return {};
      
      return Object.entries(this.weekReflection.reflections)
        .filter(([key, value]) => value && key !== 'timestamp' && key !== 'weekNumber')
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
    },
    currentWeekNumber() {
      if (!this.birthdate) return null;

      const currentDate = new Date();
      const birthDate = new Date(this.birthdate);

      currentDate.setHours(0, 0, 0, 0);
      birthDate.setHours(0, 0, 0, 0);

      const diffTime = currentDate - birthDate;
      return Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7)) + 1;
    },
    datePickerStyle() {
      return {
        backgroundColor: this.isCurrentDay ? '#ffffff' : '#f0f0f0',
        cursor: 'pointer',
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'inline-block',
        marginLeft: '0px',
        fontSize: '14px'
      };
    },
    filteredDailyHabits() {
      const today = this.getLocalDateString(new Date(this.selectedDate));
      return this.sortedDailyHabits.filter(habit => this.isHabitActiveToday(habit, today));
    },

    habitCompletionPercentage() {
      const activeHabits = this.filteredDailyHabits;
      if (activeHabits.length === 0) return 0;
      
      const completedHabits = activeHabits.filter(habit => habit.completed);
      return Math.round((completedHabits.length / activeHabits.length) * 100);
    },
    todoProgress() {
      if (this.weekTodos.length === 0) {
        return 0;
      }
      const completedTodos = this.weekTodos.filter(todo => todo.done).length;
      return (completedTodos / this.weekTodos.length) * 100;
    },
    currentWeekTodoProgress() {
      if (this.currentWeekTodos.length === 0) {
        return 0;
      }
      const completedTodos = this.currentWeekTodos.filter(todo => todo.done).length;
      return (completedTodos / this.currentWeekTodos.length) * 100;
    },

    hoursUntilDeath() {
      return this.currentHours;
    },
    sortedDailyHabits() {
      return [...this.dailyHabits].sort((a, b) => a.name.localeCompare(b.name));
    },
    progressPercentage() {
      if (this.zoomedWeek) {
        const totalWeeks = 4174; // Total number of weeks
        return ((this.zoomedWeek.number - 1) / (totalWeeks - 1)) * 100;
      }
      return 0;
    },
  },
  methods: {
    async signOut() {
      try {
        await auth.signOut(); // Sign out from Firebase
      } catch (error) {
        console.error("Error signing out: ", error);
      }
    },
    formatRatingLabel(key) {
      const labels = {
        'happiness': 'Happiness',
        'health': 'Health',
        'satisfaction': 'Satisfaction',
        'alignment': 'Alignment',
        'connections': 'Connections'
      };
      
      return labels[key] || key.charAt(0).toUpperCase() + key.slice(1);
    },

    getRatingColor(value) {
      // Generate colors based on rating value (0-10 scale)
      if (value >= 8) return '#4CAF50'; // Good - green
      if (value >= 6) return '#8BC34A'; // Above average - light green
      if (value >= 5) return '#FFC107'; // Average - yellow
      if (value >= 3) return '#FF9800'; // Below average - orange
      return '#F44336'; // Poor - red
    },

    formatReflectionQuestion(key) {
      // Map based on your WeeklyReflection component questions
      const questionMap = {
        'wins': 'What was your biggest win this week?',
        'hardships': 'What challenges or hardships did you face, and how did you handle them?',
        'lessons': 'What is one thing you learned about yourself this week?',
        'improvement': 'What is one thing you wish you had done differently this week?',
        'nextWeek': 'What are you most looking forward to next week?'
      };
      
      return questionMap[key] || key.charAt(0).toUpperCase() + key.slice(1);
    },
    setupWeeklyCheck() {
      // Store the current week number
      const currentWeek = this.getCurrentWeek();
      if (currentWeek) {
        this.lastCheckedWeekNumber = currentWeek.number;
      }
      
      // Set up an interval to check for week transitions
      this.weeklyCheckInterval = setInterval(() => {
        const currentWeek = this.getCurrentWeek();
        if (currentWeek && this.lastCheckedWeekNumber !== null) {
          // If the week has changed
          if (currentWeek.number !== this.lastCheckedWeekNumber) {
            console.log(`Week changed from ${this.lastCheckedWeekNumber} to ${currentWeek.number}`);
            this.lastCheckedWeekNumber = currentWeek.number;
            this.checkAndCreateWeeklyReviewTodo();
          }
        }
      }, 3600000); // Check every hour
    },
    async checkAndCreateWeeklyReviewTodo() {
      if (!this.birthdate || !auth.currentUser) return;
      
      try {
        const userId = auth.currentUser.uid;
        const currentWeek = this.getCurrentWeek();
        
        if (!currentWeek) return;
        
        // Check if we already have a "Complete weekly review" todo for this week
        const todoSnapshot = await getDocs(
          query(
            collection(db, `users/${userId}/todos`),
            where('weekNumber', '==', currentWeek.number),
            where('text', '==', 'Complete weekly review')
          )
        );
        
        // If no weekly review todo exists for this week, create one
        if (todoSnapshot.empty) {
          console.log(`Creating weekly review todo for week ${currentWeek.number}`);
          
          const newTodo = {
            weekNumber: currentWeek.number,
            text: 'Complete weekly review',
            done: false,
            createdAt: new Date()
          };
          
          const docRef = await addDoc(collection(db, `users/${userId}/todos`), newTodo);
          
          // Add to local state for the current week
          this.currentWeekTodos.push({
            id: docRef.id,
            ...newTodo
          });
        }
      } catch (error) {
        console.error('Error creating weekly review todo:', error);
      }
    },
    async updateHabitGroup(habit) {
      try {
        const userId = auth.currentUser.uid;
        await updateDoc(doc(db, `users/${userId}/habits`, habit.id), {
          sphere: habit.sphere
        });
        console.log(`Habit ${habit.name} group updated to ${habit.sphere}`);
      } catch (error) {
        console.error('Error updating habit group', error);
      }
    },
    async saveHoursDecimalPlaces(value) {
      try {
        const userId = auth.currentUser.uid;
        const userRef = doc(db, `users/${userId}`);
        await updateDoc(userRef, {
          hoursDecimalPlaces: value
        });
        // Update the local calculation immediately
        this.currentHours = this.calculateHours();
      } catch (error) {
        console.error('Error saving hours decimal places:', error);
      }
    },

    calculateHours() {
      if (!this.birthdate) return '0';
      
      const currentDate = new Date();
      const birthDate = new Date(this.birthdate);
      const lifeExpectancy = 80;
      
      const deathDate = new Date(birthDate);
      deathDate.setFullYear(birthDate.getFullYear() + lifeExpectancy);
      
      const diffTime = Math.abs(deathDate - currentDate);
      const diffHours = diffTime / (1000 * 60 * 60);
      
      return diffHours.toFixed(this.hoursDecimalPlaces);
    },

    startHoursTimer() {
      this.currentHours = this.calculateHours();
      this.lastUpdate = Date.now();

      this.hoursTimer = setInterval(() => {
        const now = Date.now();
        const timeDiff = now - this.lastUpdate;
        const hoursDiff = timeDiff / (1000 * 60 * 60);
        
        const newValue = parseFloat(this.calculateHours());
        this.currentHours = newValue.toFixed(this.hoursDecimalPlaces);
        this.lastUpdate = now;
      }, 50);
    },

    stopHoursTimer() {
      if (this.hoursTimer) {
        clearInterval(this.hoursTimer);
        this.hoursTimer = null;
      }
    },
    getLocalDateString(date) {
      // If nothing is passed, use current date
      if (!date) {
        date = new Date();
      }

      // If it's a string, try to parse it
      if (typeof date === 'string') {
        // If it's already in YYYY-MM-DD format and valid, return it
        if (date.match(/^\d{4}-\d{2}-\d{2}$/) && this.isValidDate(date)) {
          return date;
        }
        // Otherwise try to parse it
        date = new Date(date);
      }

      // Validate the date
      if (!this.isValidDate(date)) {
        console.error('Invalid date:', date);
        date = new Date(); // Fallback to current date
      }

      // Format in YYYY-MM-DD
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      
      return `${year}-${month}-${day}`;
    },

    getCurrentLocalDate() {
      const now = new Date();
      return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
    },
    async deleteHabit(habit) {
      try {
        const userId = auth.currentUser.uid;
        
        // Delete the habit document from Firestore
        await deleteDoc(doc(db, `users/${userId}/habits`, habit.id));
        
        // Remove from local state
        this.dailyHabits = this.dailyHabits.filter(h => h.id !== habit.id);

        // Also delete any associated habit status records
        const today = this.getCurrentLocalDate();
        const habitStatusRef = doc(db, `users/${userId}/habitStatus/${today}`);
        
        try {
          const statusDoc = await getDoc(habitStatusRef);
          if (statusDoc.exists()) {
            const currentStatus = statusDoc.data();
            // Remove this habit's status if it exists
            if (currentStatus[habit.name]) {
              await updateDoc(habitStatusRef, {
                [habit.name]: deleteField()
              });
            }
          }
        } catch (error) {
          console.error('Error cleaning up habit status:', error);
        }
      } catch (error) {
        console.error('Error deleting habit:', error);
      }
    },
    resetHabitDays() {
        this.newHabitDays = {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true
        };
      },


    async addHabit() {
      if (this.newHabitName.trim()) {
        const userId = auth.currentUser.uid;
        const habitRef = await addDoc(collection(db, `users/${userId}/habits`), {
          name: this.newHabitName.trim(),
          createdAt: new Date()
        });
        this.dailyHabits.push({ 
          id: habitRef.id, 
          name: this.newHabitName.trim(), 
          completed: false 
        });
        this.newHabitName = '';
      }
    },

    async addHabit() {
      if (this.newHabitName.trim()) {
        const userId = auth.currentUser.uid;
        const habitData = {
          name: this.newHabitName.trim(),
          createdAt: new Date(),
          days: { ...this.newHabitDays } // Save selected days
        };

        const habitRef = await addDoc(collection(db, `users/${userId}/habits`), habitData);
        
        this.dailyHabits.push({ 
          id: habitRef.id, 
          name: this.newHabitName.trim(), 
          completed: false,
          days: { ...this.newHabitDays }
        });
        
        this.newHabitName = '';
        this.resetHabitDays(); // Reset days for next habit
      }
    },
    async fetchCurrentWeekTodos() {
      const currentWeek = this.getCurrentWeek();
      if (currentWeek) {
        const userId = auth.currentUser.uid;
        const todoSnapshot = await getDocs(
          query(
            collection(db, `users/${userId}/todos`),
            where('weekNumber', '==', currentWeek.number)
          )
        );
        this.currentWeekTodos = todoSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log('Current week todos:', this.currentWeekTodos); // For debugging
      }
    },




    async fetchHabits() {
      const userId = auth.currentUser.uid;
      const habitsSnapshot = await getDocs(collection(db, `users/${userId}/habits`));
      const batch = writeBatch(db);
      let needsBatchUpdate = false;

      // Default days configuration
      const defaultDays = {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
      };

      this.dailyHabits = await Promise.all(habitsSnapshot.docs.map(async (doc) => {
        const data = doc.data();
        const habitRef = doc.ref;

        // Check if the habit needs days property
        if (!data.days) {
          needsBatchUpdate = true;
          // Add the update to the batch
          batch.update(habitRef, { days: defaultDays });

          return {
            id: doc.id,
            ...data,
            days: defaultDays,
            completed: false
          };
        }

        return {
          id: doc.id,
          ...data,
          completed: false
        };
      }));

      // If any habits needed updating, commit the batch
      if (needsBatchUpdate) {
        try {
          await batch.commit();
          console.log('Successfully updated old habits with default days');
        } catch (error) {
          console.error('Error updating old habits:', error);
        }
      }

      // Sort habits by name
      this.dailyHabits.sort((a, b) => a.name.localeCompare(b.name));
      
      // Fetch today's habit status
      await this.fetchTodayHabitStatus();
    },

    async toggleHabitDay(habit, day) {
      const updatedDays = { ...habit.days };
      updatedDays[day] = !updatedDays[day];

      try {
        const userId = auth.currentUser.uid;
        await updateDoc(doc(db, `users/${userId}/habits`, habit.id), {
          days: updatedDays
        });

        // Update the local state with a new array
        this.dailyHabits = this.dailyHabits.map(h => 
          h.id === habit.id 
            ? { ...h, days: updatedDays }
            : h
        );
      } catch (error) {
        console.error('Error updating habit days:', error);
      }
    },

    // Add this new method to update completion fill
    updateCompletionFill() {
      const completionFill = document.querySelector('.completion-fill');
      if (completionFill) {
        completionFill.style.height = `${this.habitCompletionPercentage}%`;
      }
    },

    getDayName(index) {
      const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      return days[index];
    },

    isHabitActiveToday(habit, date) {
      if (!habit.days) return true;
      
      const dayDate = new Date(date);
      const dayIndex = dayDate.getDay(); // 0 = Monday, etc.
      const dayName = [
        'monday', 'tuesday', 'wednesday', 
        'thursday', 'friday', 'saturday', 'sunday'
      ][dayIndex];
      
      console.log(`Checking habit ${habit.name} for ${dayName}:`, habit.days[dayName]);
      return habit.days[dayName] === true;
    },
    getDayLabel(day) {
      return day.charAt(0).toUpperCase();
    },


    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
      
      if (this.showDatePicker) {
        // Add the event listener when opening
        document.addEventListener('click', this.handleClickOutside);
      } else {
        // Remove the event listener when closing
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      const container = this.$el.querySelector('.date-picker-container');
      const header = this.$el.querySelector('.habits-header h4');
      
      if (this.showDatePicker && container && header) {
        // Check if the click was outside both the date picker and the header
        if (!container.contains(event.target) && !header.contains(event.target)) {
          this.showDatePicker = false;
        }
      }
    },

    changeSelectedDate(newDate) {
    // Ensure we're working with a Date object
    const dateObj = new Date(newDate);
    console.log(`Changing to date:`, dateObj);
    
    this.selectedDate = dateObj;
    const formattedDate = this.getLocalDateString(dateObj);
    this.isCurrentDay = formattedDate === this.getCurrentLocalDate();
    
    // Pass the formatted date string for the habits
    this.fetchHabitStatusForDate(formattedDate);
    this.showDatePicker = false;
  },

    isValidDate(dateString) {
      const date = new Date(dateString);
      return date instanceof Date && !isNaN(date) && date.getFullYear() > 2000;
    },

    async fetchHabitStatusForDate(date) {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const targetDate = this.getLocalDateString(date);
      if (!targetDate) {
        console.error('Invalid target date');
        return;
      }

      console.log(`Fetching habits for EXACT date: ${targetDate}`);
      const habitStatusRef = doc(db, `users/${userId}/habitStatus/${targetDate}`);

      try {
        const docSnap = await getDoc(habitStatusRef);

        if (docSnap.exists()) {
          const statusData = docSnap.data();
          console.log(`Found existing document for ${targetDate}:`, statusData);
          
          // Filter habits based on today's day of week
          this.dailyHabits = this.dailyHabits
            .filter(habit => this.isHabitActiveToday(habit, targetDate))
            .map(habit => ({
              ...habit,
              completed: statusData[habit.name] === true
            }));
        } else {
          console.log(`Creating new document for ${targetDate}`);
          
          // Create new document with ONLY today's applicable habits
          const activeTodayHabits = this.dailyHabits.filter(habit => 
            this.isHabitActiveToday(habit, targetDate)
          );

          const newHabitStatuses = {};
          activeTodayHabits.forEach(habit => {
            newHabitStatuses[habit.name] = false;
          });

          if (Object.keys(newHabitStatuses).length > 0) {
            await setDoc(habitStatusRef, newHabitStatuses);
            console.log(`Created new document for ${targetDate} with active habits:`, newHabitStatuses);
          }

          // Set local state
          this.dailyHabits = activeTodayHabits.map(habit => ({
            ...habit,
            completed: false
          }));
        }
      } catch (error) {
        console.error(`Error handling habits for ${targetDate}:`, error);
      }
    },

    // Update the existing updateHabitStatus method to use selectedDate instead of getCurrentLocalDate
    async updateHabitStatus(habit) {
      const userId = auth.currentUser.uid;
      const formattedDate = this.getLocalDateString(this.selectedDate); // Use the adjusted local date
      const habitStatusRef = doc(db, `users/${userId}/habitStatus/${formattedDate}`);
      
      try {
        const habitStatuses = {};
        this.dailyHabits.forEach(h => {
          habitStatuses[h.name] = h.completed;
        });

        await setDoc(habitStatusRef, habitStatuses, { merge: true });
        console.log('Saved habit statuses for date:', formattedDate); // For debugging
      } catch (error) {
        console.error('Error updating habit status:', error);
        habit.completed = !habit.completed; // Revert if save fails
      }
    },

    // Update the existing fetchTodayHabitStatus method
    async fetchTodayHabitStatus() {
      const localDate = this.getLocalDateString();
      await this.fetchHabitStatusForDate(localDate);

    },

    async saveQuotesAtTopState() {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, `users/${userId}`);
      await updateDoc(userRef, {
        quotesAtTop: this.quotesAtTop
      });
    },
    async loginUser() {
      this.loading = true;
      this.loginError = '';
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password);
        console.log("Login successful");
        this.isAuthenticated = true;
        await this.fetchUserData();
      } catch (error) {
        console.error('Login error:', error);
        this.loginError = 'Invalid email or password. Please try again.';
      } finally {
        this.loading = false;
      }
    },
    async signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      try {
        await signInWithPopup(auth, provider);
        this.handleUserLoggedIn();
      } catch (error) {
        console.error('Google sign-in error:', error);
        this.loginError = 'Google sign-in failed. Please try again.';
      }
    },
    async handleUserLoggedIn() {
      console.log("User logged in");
      this.isAuthenticated = true;
      try {
        const birthdate = await this.fetchUserData();
        if (birthdate) {
          this.birthdate = birthdate;
          console.log("Birthdate set:", this.birthdate);
          this.generateWeeks();
        } else {
          console.error("Failed to retrieve birthdate");
          // Handle the case where birthdate is not available
        }
      } catch (error) {
        console.error("Error in handleUserLoggedIn:", error);
      }
    },
    getLifeStageColor(age) {
      const lifeStage = this.lifeStages.find(stage => age >= stage.startAge && age <= stage.endAge);
      return lifeStage ? `life-stage-${lifeStage.name.toLowerCase().replace(' ', '-')}` : '';
    },
    toggleSettingsDrawer() {
      this.showSettingsDrawer = !this.showSettingsDrawer;
    },
    selectRandomQuote() {
      const randomIndex = Math.floor(Math.random() * this.quotes.length);
      this.randomQuote = this.quotes[randomIndex];
    },
    async saveCompactViewState() {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, `users/${userId}`);
      await updateDoc(userRef, {
        compactView: this.compactView
      });
    },
    updateLoadingState(isLoading) {
      this.isLoading = isLoading;
    },
    handleUserRegistered() {
      this.isAuthenticated = true;
      this.fetchUserData();
    },
    handleUserLoggedIn() {
      this.isAuthenticated = true;
      this.fetchUserData();
      this.generateWeeks();
    },
    async registerUser() {
      if (this.password.length < 6) {
        this.passwordError = 'Password must be at least 6 characters long';
        return;
      }

      try {
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;

        // Save additional user data to Firestore
        await setDoc(doc(db, 'users', user.uid), {
          name: this.name,
          birthdate: this.birthdate,
          email: this.email,
        });

        console.log('User registered successfully');
        this.isAuthenticated = true;
        this.fetchUserData();
      } catch (error) {
        console.error('Registration error:', error);
        this.errorMessage = error.message;
      }
    },
    showOverlay(week, event) {
      this.hoveredWeek = { week, event };
      this.$nextTick(() => {
        const overlay = this.$refs.overlay;
        const rect = event.target.getBoundingClientRect();
        overlay.style.top = `${rect.top}px`;
        overlay.style.left = `${rect.right + 10}px`;
      });
    },
    hasMilestone(weekNumber) {
      return this.milestones.some(milestone => milestone.weekNumber === weekNumber);
    },
    async fetchMilestones() {
      try {
        const userId = auth.currentUser.uid;
        const goalSnapshot = await getDocs(collection(db, `users/${userId}/lifeGoals`));
        this.milestones = goalSnapshot.docs.map(doc => ({
          weekNumber: doc.data().weekNumber,
          goal: doc.data().goal
        }));
      } catch (error) {
        console.error('Error fetching milestones:', error);
      }
    },
    async fetchTodos() {
      try {
        const userId = auth.currentUser.uid;
        const todoSnapshot = await getDocs(
          query(
            collection(db, `users/${userId}/todos`),
            where('weekNumber', '==', this.zoomedWeek.number)
          )
        );

        this.weekTodos = todoSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching todos:', error);
      }
    },
    async saveShowYearGapsState() {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, `users/${userId}`);
      await updateDoc(userRef, {
        showYearGaps: this.showYearGaps
      });
    },
    saveUserSetting(key, value) {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, `users/${userId}`);
      updateDoc(userRef, {
        [key]: value,
      })
        .catch((error) => {
          console.error('Error saving user setting:', error);
        });
    },
    startQuoteChangeInterval() {
      // Clear any existing interval
      clearInterval(this.quoteChangeInterval);
      
      // Set the interval based on the selected frequency
      switch (this.selectedFrequency) {
        case 'Hourly':
          this.quoteChangeInterval = setInterval(this.selectRandomQuote, 60 * 60 * 1000);
          break;
        case 'Daily':
          this.quoteChangeInterval = setInterval(this.selectRandomQuote, 24 * 60 * 60 * 1000);
          break;
        case 'Weekly':
          this.quoteChangeInterval = setInterval(this.selectRandomQuote, 7 * 24 * 60 * 60 * 1000);
          break;
        default:
          // 'Every Time' or any other value, no interval needed
          break;
      }
    },
    async fetchUserData() {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, `users/${userId}`);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        console.log("User data:", userData);
        if (userData.birthdate) {
          console.log("Birthdate from DB:", userData.birthdate);
          this.birthdate = userData.birthdate;
          this.showHoursUntilDeath = userData.showHoursUntilDeath || false;
          this.showYearGaps = userData.showYearGaps !== undefined ? userData.showYearGaps : false; 
          this.compactView = userData.compactView !== undefined ? userData.compactView : true;
          this.showQuotes = userData.showQuotes ?? true;
          this.showCompletionBackground = userData.showCompletionBackground ?? true; 
          this.quotePosition = userData.quotePosition || 'side'; // Default to 'side' if not set
          this.showWeeklyTodos = userData.showWeeklyTodos !== undefined ? userData.showWeeklyTodos : true;
          this.hoursDecimalPlaces = userData.hoursDecimalPlaces ?? 6;
          this.habitSettings = userData.habitSettings || [];
          this.generateWeeks();
          await this.fetchMilestones(); 
          await this.fetchHabits();
          await this.fetchTodayHabitStatus();
          await this.fetchCurrentWeekTodos();
          await this.checkAndCreateWeeklyReviewTodo();
        } else {
          console.error("Birthdate not found in user data");
        }
      } else {
        console.error("No user document found!");
      }
    },
    formatAge(age) {
      return age.toFixed(2);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Format date in local timezone
      const options = { month: 'short', day: 'numeric', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },

    formatMonthYear(week) {
      const date = new Date(week.startDate);
      // Format month/year in local timezone
      const options = { month: 'short', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },
    closeZoomedView() {
      this.zoomedWeek = null;
    },
    handleKeyPress(event) {
      const focusedElement = document.activeElement;
      const isInputOrTextarea = focusedElement && (focusedElement.tagName === 'INPUT' || focusedElement.tagName === 'TEXTAREA');

      if (!isInputOrTextarea) {
        if (event.key === 'w') {
          this.showWeeklySummary = true;
        } else if (event.key === ' ') {
          if (this.zoomedWeek) {
            this.closeZoomedView();
          } else {
            const currentWeek = this.getCurrentWeek();
            if (currentWeek) {
              this.zoomWeek(currentWeek);
            }
          }
        } else if (event.key.toLowerCase() === 'r') {  // Add this block
          const currentWeek = this.getCurrentWeek();
          if (currentWeek) {
            this.showWeeklyReflection = true;
          }
        } else if (this.zoomedWeek) {
          if (event.key === 'ArrowLeft') {
            this.goToPreviousWeek();
          } else if (event.key === 'ArrowRight') {
            this.goToNextWeek();
          }
        }
      }
    },
    getCurrentWeek() {
      if (!this.birthdate) return null;
        
        // Get current date and birthdate in local timezone
        const currentDate = new Date();
        const birthDate = new Date(this.birthdate);
        
        // Set both dates to midnight in local timezone
        currentDate.setHours(0, 0, 0, 0);
        birthDate.setHours(0, 0, 0, 0);
        
        const diffTime = currentDate - birthDate;
        const diffWeeks = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7));
        return this.getWeekByNumber(diffWeeks + 1);
      },

      generateWeeks() {
        if (!this.birthdate) {
          console.error('Birthdate is not set. Cannot generate weeks.');
          return;
        }

        // Get current date and birthdate in local timezone
        const currentDate = new Date();
        const birthDate = new Date(this.birthdate);
        
        // Set both dates to midnight in local timezone
        currentDate.setHours(0, 0, 0, 0);
        birthDate.setHours(0, 0, 0, 0);
        
        const diffTime = currentDate - birthDate;
        const diffWeeks = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7));

        const years = [];
        let yearWeeks = [];

        for (let i = 0; i < 4174.29; i++) {
          // Create dates in local timezone
          const startDate = new Date(birthDate);
          startDate.setDate(startDate.getDate() + i * 7);
          startDate.setHours(0, 0, 0, 0);

          const endDate = new Date(startDate);
          endDate.setDate(endDate.getDate() + 6);
          endDate.setHours(23, 59, 59, 999);

          const week = {
            number: i + 1,
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0],
            age: i / 52,
            isPast: i < diffWeeks,
            isCurrent: i === diffWeeks,
          };

          yearWeeks.push(week);

          if (yearWeeks.length === 52 || i === 4159) {
            years.push(yearWeeks);
            yearWeeks = [];
          }
        }

        this.years = years;
      },
    goToPreviousWeek() {
      const currentIndex = this.zoomedWeek.number - 1;
      if (currentIndex > 0) {
        const previousWeek = this.getWeekByNumber(currentIndex);
        this.zoomWeek(previousWeek);
      }
    },
    goToNextWeek() {
      const currentIndex = this.zoomedWeek.number - 1;
      if (currentIndex < 4159) {
        const nextWeek = this.getWeekByNumber(currentIndex + 2);
        this.zoomWeek(nextWeek);
      }
    },
    getWeekByNumber(weekNumber) {
      const yearIndex = Math.floor((weekNumber - 1) / 52);
      const weekIndex = (weekNumber - 1) % 52;
      return this.years[yearIndex][weekIndex];
    },
    toggleReflectionExpanded() {
      this.reflectionExpanded = !this.reflectionExpanded;
      
      // If we're expanding and don't have data yet, fetch it
      if (this.reflectionExpanded && !this.weekReflection) {
        this.fetchWeekReflection();
      }
    },
    
    async fetchWeekReflection() {
      if (!this.zoomedWeek || !auth.currentUser) return;
      
      try {
        const userId = auth.currentUser.uid;
        
        // Query the weeklyReflections collection for documents with matching weekNumber
        const reflectionsRef = collection(db, `users/${userId}/weeklyReflections`);
        const q = query(reflectionsRef, where('weekNumber', '==', this.zoomedWeek.number));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          // Get the first document (there should only be one per week)
          const docData = querySnapshot.docs[0].data();
          console.log('Found reflection data for week:', this.zoomedWeek.number, docData);
          
          this.weekReflection = {
            ratings: docData.ratings || {},
            reflections: docData.reflections || {},
            timestamp: docData.timestamp || null
          };
        } else {
          this.weekReflection = null;
          console.log('No reflection data found for week:', this.zoomedWeek.number);
        }
      } catch (error) {
        console.error('Error fetching reflection data:', error);
        this.weekReflection = null;
      }
    },
    
    formatReflectionQuestion(key) {
      // Map the database keys to readable questions
      const questionMap = {
        'accomplishments': 'What were your accomplishments this week?',
        'challenges': 'What challenges did you face?',
        'lessons': 'What lessons did you learn?',
        'gratitude': 'What are you grateful for?',
        'improvements': 'What could you improve next week?',
        'goals': 'What are your goals for next week?'
        // Add any other reflection questions you have in your system
      };
      
      return questionMap[key] || key;
    },
    async zoomWeek(week) {
      // Reset reflection state
      this.reflectionExpanded = false;
      this.weekReflection = null;
      
      // Existing code
      this.zoomedWeek = week;
      this.weeksUntil80 = 4174 - week.number;

      try {
        const userId = auth.currentUser.uid;
        const goalSnapshot = await getDocs(
          query(
            collection(db, `users/${userId}/lifeGoals`),
            where('weekNumber', '==', week.number)
          )
        );

        this.weekGoals = goalSnapshot.docs.map((doc) => doc.data().goal);

        const summarySnapshot = await getDocs(
          query(
            collection(db, `users/${userId}/lifeSummaries`),
            where('weekNumber', '==', week.number)
          )
        );

        if (!summarySnapshot.empty) {
          this.weekSummary = summarySnapshot.docs[0].data().summary;
        } else {
          this.weekSummary = '';
        }

        // Fetch todos for the current week
        await this.fetchTodos();
      } catch (error) {
        console.error('Error fetching goals and summary:', error);
      }
    },
    startEditingSummary() {
      this.editingSummary = true;
      this.$nextTick(() => {
        const textarea = this.$el.querySelector('.summary-section textarea');
        if (textarea) {
          textarea.focus();
        }
      });
    },
    async saveSummary() {
      if (this.weekSummary.trim() !== '') {
        try {
          this.isSaving = true; // Set the saving state to true

          const userId = auth.currentUser.uid;
          const summaryQuery = query(
            collection(db, `users/${userId}/lifeSummaries`),
            where('weekNumber', '==', this.zoomedWeek.number)
          );
          const summarySnapshot = await getDocs(summaryQuery);

          if (summarySnapshot.empty) {
            await addDoc(collection(db, `users/${userId}/lifeSummaries`), {
              weekNumber: this.zoomedWeek.number,
              summary: this.weekSummary,
            });
          } else {
            const summaryRef = summarySnapshot.docs[0].ref;
            await updateDoc(summaryRef, {
              summary: this.weekSummary,
            });
          }

          // Provide visual feedback or perform any desired actions
          console.log('Summary saved successfully');
          // You can show a toast message, display a success alert, etc.

        } catch (error) {
          console.error('Error saving summary:', error);
          this.editingSummary = false; // Set editingSummary back to false if the summary is empty

          // Handle the error and provide appropriate feedback to the user
        } finally {
          this.isSaving = false; // Set the saving state back to false
          this.editingSummary = false; // Set editingSummary back to false if the summary is empty

        }
      }
    },
    async saveGoal() {
      if (this.goalInput.trim() !== '') {
        try {
          const userId = auth.currentUser.uid;
          const docRef = await addDoc(collection(db, `users/${userId}/lifeGoals`), {
            weekNumber: this.zoomedWeek.number,
            goal: this.goalInput,
          });

          // Add the new goal to the weekGoals array
          this.weekGoals.push(this.goalInput);

          // Add the new milestone to the milestones array
          this.milestones.push({ weekNumber: this.zoomedWeek.number, goal: this.goalInput });

          this.goalInput = '';
        } catch (error) {
          console.error('Error saving goal:', error);
        }
      }
    },
    async deleteMilestone(index) {
      try {
        const userId = auth.currentUser.uid;
        const goalSnapshot = await getDocs(
          query(
            collection(db, `users/${userId}/lifeGoals`),
            where('weekNumber', '==', this.zoomedWeek.number),
            where('goal', '==', this.weekGoals[index])
          )
        );

        if (!goalSnapshot.empty) {
          const goalRef = goalSnapshot.docs[0].ref;
          await deleteDoc(goalRef);

          // Remove the milestone from the weekGoals array
          this.weekGoals.splice(index, 1);

          // Remove the milestone from the milestones array
          const milestoneIndex = this.milestones.findIndex(
            (milestone) =>
              milestone.weekNumber === this.zoomedWeek.number && milestone.goal === this.weekGoals[index]
          );
          if (milestoneIndex !== -1) {
            this.milestones.splice(milestoneIndex, 1);
          }
        }
      } catch (error) {
        console.error('Error deleting milestone:', error);
      }
    },
    async addTodo() {
      if (this.todoInput.trim() !== '') {
        try {
          const userId = auth.currentUser.uid;
          const currentWeek = this.getCurrentWeek();
          const weekNumber = this.zoomedWeek ? this.zoomedWeek.number : currentWeek.number;

          const newTodo = {
            weekNumber: weekNumber,
            text: this.todoInput,
            done: false,
          };
          
          const docRef = await addDoc(collection(db, `users/${userId}/todos`), newTodo);
          
          const todoWithId = { id: docRef.id, ...newTodo };

          // If we're in the zoomed view and it's the current week, add to weekTodos
          if (this.zoomedWeek && this.zoomedWeek.number === weekNumber) {
            this.weekTodos.push(todoWithId);
          }

          // Always add to currentWeekTodos if it's for the current week
          if (weekNumber === currentWeek.number) {
            this.currentWeekTodos.push(todoWithId);
          }

          this.todoInput = '';
        } catch (error) {
          console.error('Error adding to-do item:', error);
        }
      }
    },
    async updateTodoStatus(todo) {
      if (!todo.id) {
        console.error('Todo item has no ID:', todo);
        return;
      }

      try {
        const userId = auth.currentUser.uid;
        const todoRef = doc(db, `users/${userId}/todos`, todo.id);
        await updateDoc(todoRef, {
          done: todo.done,
        });

        // Update the todo in currentWeekTodos if it exists
        const index = this.currentWeekTodos.findIndex(t => t.id === todo.id);
        if (index !== -1) {
          this.currentWeekTodos[index].done = todo.done;
        }

        // Update the todo in weekTodos if it exists
        const weekTodoIndex = this.weekTodos.findIndex(t => t.id === todo.id);
        if (weekTodoIndex !== -1) {
          this.weekTodos[weekTodoIndex].done = todo.done;
        }
      } catch (error) {
        console.error('Error updating to-do item status:', error);
      }
    },
    async deleteTodo(todo) {
      if (!todo.id) {
        console.error('Todo item has no ID:', todo);
        return;
      }

      try {
        const userId = auth.currentUser.uid;
        await deleteDoc(doc(db, `users/${userId}/todos`, todo.id));

        // Remove the to-do item from the weekTodos array
        this.weekTodos = this.weekTodos.filter((item) => item.id !== todo.id);

        // Remove from currentWeekTodos if it exists there
        this.currentWeekTodos = this.currentWeekTodos.filter((item) => item.id !== todo.id);
      } catch (error) {
        console.error('Error deleting to-do item:', error);
      }
    },
  },
};
</script>

<style>
.life-grid {
  width: 75%;
  margin: 20px auto;
  position: relative;
  z-index: 1;
}


.completion-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: white;
  transition: opacity 0.3s ease;
}

.completion-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: white;
  transition: opacity 0.3s ease;
}

.completion-fill {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, #f3f9ff 60%, #f3f9ff 100%);
  transition: height 0.6s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s ease;
}

.completion-background-enabled {
  opacity: 1;
}


.year-row {
  display: grid;
  grid-template-columns: repeat(52, 1fr);
  gap: 2px;
  margin-bottom: 2px;
}



.square {
  background-color: #f0f0f0;
  aspect-ratio: 1/1;
  cursor: pointer;
  position: relative;

}


.milestone-indicator {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  background-color: #fff900;
  border-radius: 50%;
  z-index: 1;
}



.square:hover {
  box-shadow: 0px 1px 10px 2px rgb(56 56 56 / 10%);
    scale: 1.1;
  }

.past-week {
  background-color: #afafaf;
}

.zoomed-view {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px 20px 50px 20px;
  width: 60%;
  min-height: 60%;
  max-height: 80%;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
  overflow-y: auto;
}
.zoomed-view-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.goal-week  {
  display: inline-grid;
}

.arrow-button {
  position: absolute;
  bottom: 0;
  transform: translateY(-50%);
  /* background-color: #f0f0f0; */
  color: #8b8b8b;
  font-size: 14px;
  padding: 10px;
  z-index: 100;
  width: 100px;
  cursor: pointer;
  transition: all .3s ease-in;
}

.arrow-button:hover {
  font-weight: 600;
  scale: 1.1;
  color: #333;
  transition: all .3s ease-in;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.goal-list {
  text-align: left;
  width: 80%;
  margin: 20px auto;
}

.goal-list h3 {
  margin-bottom: 10px;
}

.goal-list ul {
  list-style-type: disc;
  padding-left: 20px;
}

.week-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.week-number {
  font-size: 30px;
  font-weight: 600;
}

.weeks-until-80 {
  font-size: 14px;
  bottom: 20px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}

.age {
  font-size: 30px;
  font-weight: 600;
}

.goal-list div {
  margin-bottom: 5px;
}
.age-title {
  font-size: 10px;
  font-weight: 600;
  line-height: 0px;
  text-align: right;
  margin-right: 5px;
}

.week-title {
  font-size: 10px;
  font-weight: 600;
  line-height: 0px;
  text-align: left;
}

.summary-section, .goal-section, .todo-section {
  width: 80%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 20px;
}

.todo-section2 {
  width: 95%;
  margin: 0 5px;
  display: flex;
}

.to-do-list {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: left;
}


.summary-saved {
  background-color: #e0ffe0;
  border-color: #4caf50;
}

.weekly-summary-button {
  background-color: white !important;
  color: black !important;
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
  margin-bottom: 20px;
  margin-left: 10px !important;
}

.life-stage-early-years.past-week {
  background-color: #caf0f8;
}

.life-stage-school-years.past-week {
  background-color: #ade8f4;
}

.life-stage-young-adult.past-week {
  background-color: #90e0ef;
}

.life-stage-adulthood.past-week {
  background-color: #48cae4;
}

.life-stage-senior-years.past-week {
  background-color: #00b4d8;
}

.life-stage-senior-years {
  background-color: #eed9c863;
}

.year-row.year-gap {
  margin-bottom: 20px;
}

.delete-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 10px;
  color: lightgrey;
}

.delete-icon:hover {
  color: red;
  scale: 1.25;
}
.todo-done {
  text-decoration: line-through;
  color: #b0b0b0;
}

.todo-item {
  display: flex;
  height: 30px;
  align-items: center;
}

.todo-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.todo-checkbox {
  margin-right: 8px;
  height: auto;
  padding: 0;
  max-width: 30px;
}

.todo-input {
  width: 100%;
}

.todo-text {
  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.life-grid.compact-view {
  margin: 10px 31%;
}


.compact-view .year-row {
  grid-template-columns: repeat(104, 1fr);
}

.month-year-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  pointer-events: none;
  z-index: 1000;
  transition: opacity 0.3s;
}

.summary-text {
  padding: 40px;
  border: 1px solid #ededed;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  min-height: 100px;
  transition: background-color 0.3s;
  color: black;
  text-align: left;
  overflow: auto;
}

.summary-text.no-summary {
  color: #bbbbbb;
}

.summary-text:hover {
  background-color: #f0f0f0;
}

.week-details {
  text-align: center;
  width: 80%;
  margin: 20px auto 15px;
  font-style: italic;
  font-size: 20px;
  color: #747474;
}

.week-details p {
  font-size: 16px;
}

.progress-container {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 20px;
  overflow: hidden;
}

.progress-bar {
  width: 0;
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.5s ease;
}

.todo-progress-container {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 10px;
  overflow: hidden;
}

.todo-progress-bar {
  width: 0;
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.5s ease;
}
.random-quote {
  text-align: center;
  font-style: italic;
  width: 90%;
  margin: 0px auto;
}
.current-week {
  background-color: #ff0000 !important;
}

.settings-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
}


.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

.login-form {
  padding: 20px;
  width: 500px;
  border-radius: 5px;
}

.registration-form {
  padding: 20px;
  width: 500px;
  border-radius: 5px;
}
.login-container  {
  width: 600px;
  margin-top: 10px;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

label {
  flex: 1;
  font-weight: bold;
  margin-right: 10px;
  text-align: right;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  flex: 2;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login-loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.login-loading-screen p {
  color: #59a7ff;
  font-size: 28px;
  font-weight: 600;
}


.loader-container-login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  border-top: 6px solid #9ec9ff;
  border-right: 6px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.hidden {
    display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}






.loader-container-login {
  --uib-size: 75px;
  --uib-color: #83c4ff;
  --uib-speed: 2s;
  position: relative;
  margin: 0 auto;
  height: var(--uib-size);
  width: var(--uib-size);
  animation-delay: 4s; /* Add this line to delay the animation by 2 seconds */
}

.loader-container-login::before,
.loader-container-login::after,
.dot::before,
.dot::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: var(--uib-color);
  animation: pulse var(--uib-speed) linear infinite;
  transform: scale(0);
  opacity: 0;
  transition: background-color 0.3s ease;
}

.loader-container-login::after {
  animation-delay: calc(var(--uib-speed) / -4 + 4s); /* Add 2s to the existing delay */
}

.dot::before {
  animation-delay: calc(var(--uib-speed) * -0.5 + 2s); /* Add 2s to the existing delay */
}

.dot::after {
  animation-delay: calc(var(--uib-speed) * -0.75 + 2s); /* Add 2s to the existing delay */
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

  .tally-container {
      margin-bottom: 20px;
    }

    .tally {
      width: 80px;
      height: 100px;
      position: relative;
    }

    .tally-mark {
      position: absolute;
      width: 20px;
      height: 100px;
      background-color: #333;
      transform-origin: center;
      animation: drawTally .4s linear forwards;
      opacity: 0;
    }

    .tally-mark:nth-child(1) {
      left: 30px;
      animation-delay: 0s;
    }

    .tally-mark:nth-child(2) {
      left: 60px;
      animation-delay: 0.4s;

    }

    .tally-mark:nth-child(3) {
      left: 90px;
      animation-delay: 0.8s;
    }

    .tally-mark:nth-child(4) {
      left: 120px;
      animation-delay: 1.2s;
    }

    .tally-mark:nth-child(5) {
      width: 170px;
      height: 18px !important;
      top: 40px;
      transform: rotate(338deg);
      /* border-radius: 6px; */
      left: 0px;
      animation-delay: 1.6s;
    }

    @keyframes drawTally {
      0% {
        height: 0;
        opacity: 1;
      }
      100% {
        height: 100%;
        opacity: 1;
      }
    }

.error {
  font-size: 12px;
  color: red;
  margin-bottom: 0;
  text-align: right;
}
.registration-container {
  width: 600px;
  margin: 0 auto;
}

.registration-form {
  padding: 20px;
  border-radius: 5px;
}

.registration-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

label {
  flex: 1;
  font-weight: bold;
  margin-right: 10px;
  text-align: right;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"] {
  flex: 2;
  width: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}


.terms-group {
  display: flex;
  align-items: center;
  margin: 15px;
}

.terms-group label {
  margin-left: 5px;
}

.terms-link {
  color: #3e9bff;
  text-decoration: none;
}

.hours-until-80 {
  width: 80%;
  font-weight: bold;
  font-size: 40px;
  display: grid;
  margin: 10px auto;
  text-align: center;
}

.hours-until-label {
  font-size: 8px;
}


.settings-toggle {
  height: 45px;
}
.signoutbutton:hover {
  background-color: #f10000;
  transition: 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.container {
  text-align: left;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.life-view-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.daily-habits {
  position: fixed;
  overflow: scroll;
  height: 100%;
  left: 20px;
  top: 20px;
  width: 25%;
  z-index: 10;
  padding-bottom: 100px;
}

.quote-container {
  width: 100%;
  padding: 10px;
  z-index: 10;
}

.quote-top {
  top: 0;
  left: 0;
  right: 0;
  max-width: 40%;
  position: relative;
  margin: 0 auto;
}

.quote-bottom {
  bottom: 0;
  position: relative;
  max-width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
}

/* Adjust the existing .daily-quote class */
.daily-quote {
  position: fixed;
  left: 20px;
  bottom: 20px;
  transform: translateY(-50%);
  width: 25%;
  z-index: 1;
  padding: 10px;
  border-radius: 8px;
}



.habit-item {
  display: flex;
  align-items: center;
  height: 30px;
}

.habit-item :deep(.v-checkbox) {
  margin-right: 10px;
}


.habit-text {
  font-size: 14px;
  color: #585858;
  line-height: 13px;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.habit-done {
  color: #4CAF50 !important;
  text-decoration: line-through;
}


.habit-item :deep(.v-label) {
  line-height: 15px !important;
  height: 35px !important;
  transition: color 0.3s ease !important;
}

.habit-item:hover :deep(.v-label) {
  color: black !important;
}

.habit-item :deep(.v-checkbox--checked .v-label) {
  color: #4CAF50 !important;
  text-decoration: line-through !important;
}

.habit-item :deep(.v-checkbox:not(.v-checkbox--checked) .v-label) {
  color: #757575 !important;
}

.habit-item :deep(.v-selection-control) {
  line-height: 15px !important;
  height: 35px !important;
}

/* Add this new rule for better hover effect */
.habit-item:hover :deep(.v-checkbox:not(.v-checkbox--checked) .v-label) {
  color: black !important;
}

.todo-checkbox {
  height: auto;
  padding: 0;
  max-width: 30px;
}

.add-habit-button {
  background-color: white !important;
  color: black !important;
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
  margin-bottom: 20px;
  margin-left: 10px !important;
  width: 150px;
}

/* Media query for smaller screens */
@media (max-width: 800px) {
  .daily-habits {
    position: static;
    width: 50%;
    margin: 20px auto;
    transform: none;
  }
}


.weekly-todos {
  position: fixed;
  right: 20px;
  top: 20px;
  width: 25%;
  z-index: 2;
  padding: 10px;
  border-radius: 8px;
}

.weekly-todos .todo-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.weekly-todos .todo-text {
  margin-left: 10px;
  font-size: 14px;
}

.current-week-todo-progress-container {
  width: 100%;
    height: 6px;
    background-color: #f0f0f0;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
}

/* Style for the modal container */
.v-dialog .v-card {
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  max-height: 80vh;
  overflow-y: auto;
}

/* Style for the modal title */
.v-dialog .v-card-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

/* Style for the modal text */
.v-dialog .v-card-text {
  font-size: 16px;
  line-height: 1.6;
  color: #333333;
}

/* Style for paragraphs */
.v-dialog .v-card-text p {
  margin-bottom: 10px;
}

/* Style for unordered lists */
.v-dialog .v-card-text ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 10px;
}

/* Style for ordered lists */
.v-dialog .v-card-text ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 10px;
}

/* Style for list items */
.v-dialog .v-card-text ul li,
.v-dialog .v-card-text ol li {
  margin-bottom: 5px;
}

/* Style for headings */
.v-dialog .v-card-text h1,
.v-dialog .v-card-text h2,
.v-dialog .v-card-text h3 {
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Style for the close button */
.v-dialog .v-card-actions {
  justify-content: flex-end;
}

.privacy-policy {
  width: 80%;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: block; /* Default to showing */
  position: relative;
  z-index: 1;
}

.privacy-policy h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.privacy-policy p {
  margin-bottom: 15px;
}

.privacy-policy ul {
  margin-bottom: 15px;
  padding-left: 20px;
}

.privacy-policy strong {
  font-weight: 600;
}

.day-indicator {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 4px;
  background-color: #fafafa;
  color: #999;
  margin: 0 2px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.day-indicator:hover {
  background-color: #f0f0f0;
  transform: scale(1.1);
}

.day-active {
  background-color: #e3f2fd;
  color: #1976d2;
  font-weight: 500;
}

.day-active:hover {
  background-color: #bbdefb;
}

.habit-list-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0;
}

.habit-name {
  flex: 1;
  margin: 0 12px;
}

.habit-days {
  display: flex;
  gap: 2px;
}

.habit-list-item {
  border-bottom: 1px solid #eee;
}

.days-checkboxes {
  display: flex;
  justify-content: center;
}

.days-selector {
  margin-top: -20px;
}

.hidden-when-zoomed {
  display: none !important;
}

.decimal-places-setting {
  padding: 0 16px;
  margin-bottom: -30px;
  margin-top: 10px;
}

.decimal-places-slider {
  margin-top: 0px;
}

.login-toggle {
  border: solid thin lightgrey;
}


.mobile-close-button {
  display: none;  /* Hidden by default */
  position: fixed;
  top: 20px;
  left: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  z-index: 101;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #666;
  padding: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.mobile-close-button:hover {
  background-color: #e0e0e0;
  color: #333;
}

.habits-header {
  position: relative;
}

.habits-header h4 {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.date-picker-container {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background: white;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  border-radius: 4px;
  margin-top: 8px;
}

.habit-item {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.habit-group-select {
  border: solid thin lightblue;
  border-radius: 4px;
  padding: 2px;
  -webkit-appearance: listbox;
  margin-right: 20px;
}

:deep(.v-select .v-field .v-field__input > input) {
  display: none !important;
}


.habit-item.past-day {
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .completion-background {
    display: none;
  }

  .date-picker-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
  }

  .quote-container {
    z-index: 1;
  }

  .login-form, .registration-form {
    width: 300px;
    margin: 0 auto;
  }

  .login-toggle-parent {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .daily-habits {
    margin: 10px;
    width: 100%;
    position: unset;
  }

  .quote-top {
    max-width: 90%;
  }

  .demo-setup {
    padding: 30px;
  }

  .random-quote {
    width: 100%;
  }

  .hours-until-80 {
    font-size: 30px;
  }

  .life-grid.compact-view {
    margin: 0px 0%;
  }

  .life-grid {
    width: 200%;
  }

  /* Updated zoomed view styles for mobile */
  .zoomed-view-backdrop {
    background-color: white;
    padding: 0;
  }

  .zoomed-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    transform: none;
    border-radius: 0;
    box-shadow: none;
    padding: 20px;
    padding-top: 70px;
    margin: 0;
    overflow-y: auto;
  }

  .mobile-close-button {
    display: flex;  /* Show only on mobile */
  }

  .summary-section, 
  .goal-section, 
  .to-do-list,
  .week-details {
    width: 100%;
  }

  .arrow-button {
    position: fixed;
    bottom: 20px;
    padding: 15px;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
  }

  .left-arrow {
    left: 0;
  }

  .right-arrow {
    right: 0;
  }

  .weeks-until-80 {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 10px;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
  }

  .weekly-todos {
    position: unset;
    width: unset;
  }
}

.completion-percentage {
  position: fixed;
  bottom: 20px;
  right: 80px; /* Position to the left of the settings icon */
  font-size: 80px;
  font-weight: bold;
  color: #48cae4;
  /* background-color: white; */
  padding: 8px 12px;
  border-radius: 8px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  z-index: 999;
  transition: all 0.3s ease;
}

/* Media query for mobile */
@media screen and (max-width: 768px) {
  .completion-percentage {
    bottom: 60px;
    right: 20px;
    font-size: 16px;
    padding: 6px 10px;
  }
}


.reflection-section {
  width: 80%;
  margin: 20px auto;
  border: 1px solid #ededed;
  border-radius: 4px;
  overflow: hidden;
}

.reflection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reflection-header:hover {
  background-color: #f0f0f0;
}

.reflection-content {
  padding: 20px;
  max-height: 400px;
  overflow-y: auto;
}

.reflection-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 15px;
}

.reflection-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.reflection-question {
  font-weight: 500;
  margin-bottom: 8px;
  color: #555;
  font-size: 16px;
}

.reflection-answer {
  color: #333;
  line-height: 1.5;
  white-space: pre-wrap;
}

.no-reflection {
  color: #888;
  font-style: italic;
  text-align: center;
  padding: 20px 0;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .reflection-section {
    width: 100%;
  }
  
  .reflection-header {
    padding: 10px 15px;
  }
  
  .reflection-content {
    padding: 15px;
  }
}


</style>