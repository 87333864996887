import { createApp } from 'vue';
import App from './App.vue';
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth } from './firebaseInit'; // import from firebaseInit
// Vuetify Imports
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles' // Make sure to import the styles


const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
  },
})

// Wait for Firebase authentication to initialize before mounting the app
let app;

onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App);
    app.use(vuetify); // Use Vuetify instance here


    // Add the auth and db instances to the app's global properties
    app.config.globalProperties.$auth = auth;
    app.config.globalProperties.$db = db;

    app.mount('#app');
  }
});
